<template>
  <section fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <p class="text-h5 secondary--text">Configuración</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-autocomplete
            label="Seleccione un lote *"
            outlined
            v-model="lote"
            :items="ctlLotes"
            :disabled="$route.params?.idSubasta ? true : false"
            item-value="id"
            item-text="nombre"
            :loading="loadingLote"
            @change="getLoteDetail"
            :error-messages="errorLote"
            @blur="$v.lote.$touch()"
        />
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col cols="12" sm="12" md="12">
        <p>Detalle del lote</p>

        <v-data-table
            :headers="headers"
            :items="loteDetails"
            :items-per-page="-1"
            hide-default-footer
        >
          <template #[`item.monto_sub_proceso_obs`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_sub_proceso_obs)
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Calendarios -->
    <v-row class="row-calendar">
      <v-col cols="6">
        <vc-date-picker
            class="py-0 px-0"
            v-model="fechaInicio"
            mode="datetime"
            :min-date="fecha_actual"
            locale="es"
            :popover="config_calendar"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
                class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                label="Fecha y hora de inicio*"
                prepend-inner-icon="mdi-calendar"
                readonly
                :disabled="isShow"
                :value="getFechaInicio"
                v-on="inputEvents"
                outlined
                :error-messages="errorFechaInicio"
                @change="$v.fechaInicio.$touch()"
                @blur="$v.fechaInicio.$touch()"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="6">
        <vc-date-picker
            v-model="fechaFin"
            mode="datetime"
            locale="es"
            :popover="config_calendar"
            :min-date="fechaInicio"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
                class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                label="Fecha y hora de fin*"
                prepend-inner-icon="mdi-calendar"
                readonly
                :disabled="isShow"
                :value="getFechaFin"
                v-on="inputEvents"
                outlined
                :error-messages="errorFechaFin"
                @change="$v.fechaFin.$touch()"
                @blur="$v.fechaFin.$touch()"
            />
          </template>
        </vc-date-picker>
      </v-col>
    </v-row>
    <v-row>
      <!-- Textfield y select juntos -->
      <v-row class="pl-8 my-4">
        <v-col cols="12" md="3" class="mx-0 pl-0">
          <v-select
              class="custom-select"
              v-model="unidadTiempo"
              :items="tiempo_entre_ofertas_unidad_options"
              item-text="text"
              item-value="value"
              label="Unidad de tiempo*"
              :readonly="isShow"
              outlined
              @change="validarCantidadTiempoOfertas"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3" class="mx-0 pl-0">
          <v-text-field
              class="custom-text-field"
              v-model="tiempo_maximo_desierto"
              label="Tiempo para declarar desierta la subasta"
              outlined
              min="0"
              :max="maxValueTiempoOferta"
              type="number"
              :readonly="isShow"
              :error-messages="errorTiempoOferta"
              @keypress="tiempoOfertapreventKey"
              @keydown="longitudTiempoOff"
              @blur="$v.tiempoOferta.$touch()"
              :disabled="unidadTiempo === null"
              @change="validarCantidadTiempoOfertas"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="mx-0 pl-0">
          <v-text-field
              class="custom-text-field"
              v-model="tiempo_maximo_oferta"
              label="Tiempo máximo de espera entre ofertas"
              outlined
              min="0"
              :max="maxValueTiempoOferta"
              type="number"
              :readonly="isShow"
              :error-messages="errorTiempoOferta"
              @keypress="tiempoOfertapreventKey"
              @keydown="longitudTiempoOff"
              @blur="$v.tiempoOferta.$touch()"
              :disabled="unidadTiempo === null"
              @change="validarCantidadTiempoOfertas"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-autocomplete
            label="Seleccione el tipo de subasta*"
            outlined
            v-model="ctlSubasta"
            :items="ctlSubastas"
            return-object
            item-text="nombre"
            :error-messages="errorCtlSubasta"
            @blur="$v.ctlSubasta.$touch()"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!-- subasta por montos -->
    <v-row v-if="ctlSubasta?.tipo_operacion === 'unitaria_monto'">
      <v-col cols="12" md="2">
        <v-text-field
            outlined
            v-model="cantidadElementos"
            label="Cantidad elementos por lote"
            readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="['unitaria_monto', 'resta_monto'].includes(ctlSubasta?.tipo_operacion)">
      <v-col cols="12" md="2">
        <v-text-field
            outlined
            v-model="saltoOfertas"
            label="Salto entre ofertas*"
            prefix="$"
            @keypress="validatorKey($event)"
            :error-messages="errorSaltoOferta"
            :readonly="isShow"
            @blur="$v.saltoOfertas.$touch()"
            @input="$v.saltoOfertas.$touch()"
            @paste="(e) => e.preventDefault()"
            v-if="['resta_monto'].includes(ctlSubasta?.tipo_operacion)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
            outlined
            v-model="montoInicial"
            label="Monto inicial*"
            :readonly="isShow"
            prefix="$"
            :error-messages="errorMontoInicial"
            @keypress="validatorKey($event)"
            @blur="$v.montoInicial.$touch()"
            @input="$v.montoInicial.$touch()"
            @paste="(e) => e.preventDefault()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
        v-if="['resta_porcentaje_publicidad','resta_porcentaje', 'suma_porcentaje'].includes(ctlSubasta?.tipo_operacion)">
      <v-col cols="12" md="6"
             v-if="['resta_porcentaje', 'resta_porcentaje_publicidad'].includes(ctlSubasta?.tipo_operacion)">
        <v-text-field
            outlined
            v-model="porcentaje"
            label="Porcentaje inicial*"
            :error-messages="errorPorcentajeInicial"
            @keypress="validatorKey($event)"
            @blur="$v.porcentaje.$touch()"
            @input="$v.porcentaje.$touch()"
            @paste="(e) => e.preventDefault()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Esta subasta será porcentual en base al monto de {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(montoInicial)
          }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn class="mr-4" outlined color="secondary" @click="$router.back()">
          Volver
        </v-btn>
        <v-btn
            dark
            color="secondary"
            :loading="loadingSaveBtn"
            @click="saveSalaConf()"
        >
          {{ $route.params.idSubasta ? "Editar" : "Guardar" }}

        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import {required, maxValue} from "vuelidate/lib/validators";
import {mapMutations, mapState, mapActions} from "vuex";
import moment from "moment-timezone";

// Función de vuelidate para validar que el monto no acepte maws de dos
// decimales, puede tener cero decimales o uno
function decimalValidator(value) {
  return (
      value?.toString().match(/^-?\d*(\.\d{0,2})?$/) != null &&
      value?.toString().match(/^-?\d*(\.\d{0,2})?$/)[0] === value.toString()
  );
}

function decimalValidator4(value) {
  return (
      value?.toString().match(/^-?\d*(\.\d{0,2})?$/) != null &&
      value?.toString().match(/^-?\d*(\.\d{0,2})?$/)[0] === value.toString()
  );
}

function dateValidation() {
  return (
      moment(this.fechaInicio).format("YYYY-MM-DD HH:mm") <
      moment(this.fechaFin).format("YYYY-MM-DD HH:mm")
  );
}

function validarRequerido(value) {
  return this.ctlSubasta?.tipo_operacion === 'resta_monto' ? value !== null && value !== undefined && value !== "" : true;
}

function validMontoInicial(value) {
  return value > Number(this.saltoOfertas);
}

// function maxPorcentaje(value) {
//   return value > 100;
// }

export default {
  name: "ConfiguracionSalaView",
  components: {MoneyTextFieldComponent},

  data: () => ({
    montoTotalLote: null,
    ctlSubastas: [],
    headers: [
      {
        text: "Nombre",
        value: "nombre_agrupacion",
      },
      {
        text: "Detalle",
        value: "unidad",
      },
      {
        text: "Cantidad",
        value: "total_sub_proceso",
      },
      {
        text: "Monto($)",
        value: "monto_sub_proceso_obs",
      },
    ],
    tiempo_maximo_oferta: null,
    tiempo_maximo_desierto: null,
    tiempo_cierre: null,
    items: [],
    tiempo_entre_ofertas_unidad_options: [
      // {text: "Segundos", value: "seconds"},
      {text: "Minutos", value: "minutes"},
      // {text: "Horas", value: "hours"},
    ],
    // Configuración del calendario
    config_calendar: {
      visibility: "click",
    },
    ctlLotes: [],
    loteDetails: [],
    loadingLote: false,
    loadingSaveBtn: false,
    fecha_actual: moment().format("YYYY-MM-DD HH:mm"),
    ctlSubasta: null,
  }),
  watch: {
    ctlSubasta: {
      handler: async function (val) {
        this.form.id_ctl_subasta = val?.id
        if (!!val && this.ctlSubasta?.tipo_operacion !== 'resta_monto' && this.montoInicial === null) {
          this.saltoOfertas = null
          this.montoInicial = this.montoTotalLote

        }
      },
      deep: true,
    },

  },
  validations() {
    const objValidaciones = {
      lote: {required},
      fechaInicio: {required, dateValidation},
      fechaFin: {required, dateValidation},
      ctlSubasta: {required},
      tiempoOferta: {required},
      unidadTiempo: {required},
      montoInicial: {
        required,
        decimalValidation: (value) => decimalValidator(value),
      },
    }
    if (['resta_porcentaje_publicidad', 'resta_porcentaje'].includes(this.ctlSubasta?.tipo_operacion)) {
      objValidaciones.porcentaje = {
        required,
        decimalValidation: (value) => decimalValidator4(value),
        // maxValue: (value) => maxPorcentaje(value),
      }
    }

    if (this.ctlSubasta?.tipo_operacion === 'unitaria_monto') {
      objValidaciones.cantidad = {
        required,
      }
    }
    if (this.ctlSubasta?.tipo_operacion === 'resta_monto') {
      objValidaciones.saltoOfertas = {
        required,
        decimalValidation: (value) => decimalValidator(value),
      }
    }
    // console.log(objValidaciones)
    return objValidaciones
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    ...mapState("etapaSubastaInversaStore", ["form", "isShow"]),
    breadcrumbsItems() {
      return [
        {
          text: "Proceso",
          disable: true,
          href: "/pac-procesos",
        },
        {
          text: this.procesoData?.codigo_proceso,
          disable: true,
          href: "#",
        },
        {
          text: "Subasta inversa",
          disable: true,
          href: "#",
        },
        {
          text: "Sala",
          disable: true,
          href: "#",
        },
        {
          text: "Configuración",
          disable: true,
          href: "#",
        },
      ];
    },
    getFechaInicio() {
      if (this.form.fecha_hora_inicio) {
        const fecha = this.$moment(this.form.fecha_hora_inicio).format(
            "DD/MM/YYYY HH:mm A"
        );
        return fecha;
      } else {
        return "";
      }
    },
    getFechaFin() {
      return this.form.fecha_hora_fin
          ? this.$moment(this.form.fecha_hora_fin).format("DD/MM/YYYY HH:mm A")
          : "";
    },
    lote: {
      get() {
        return this.form.lote;
      },
      set(value) {
        this.form.lote = value;
      },
    },
    fechaInicio: {
      get() {
        return this.form.fecha_hora_inicio;
      },
      set(value) {
        this.form.fecha_hora_inicio = value
            ? this.moment(value).format("YYYY-MM-DD HH:mm:ss")
            : "";
      },
    },
    fechaFin: {
      get() {
        return this.form.fecha_hora_fin;
      },
      set(value) {
        this.form.fecha_hora_fin = value
            ? this.moment(value).format("YYYY-MM-DD HH:mm:ss")
            : "";
      },
    },
    saltoOfertas: {
      get() {
        return this.form.salto_ofertas;
      },
      set(value) {
        this.form.salto_ofertas = value;
      },
    },
    cantidadElementos: {
      get() {
        return this.form.cantidad;
      },
      set(value) {
        this.form.cantidad = value;
      },
    },
    tiempoOferta: {
      get() {
        return this.form.tiempo_oferta_bk;
      },
      set(value) {
        this.form.tiempo_oferta_bk = Number(value);
      },
    },
    unidadTiempo: {
      get() {
        return this.form.unidad_tiempo;
      },
      set(value) {
        this.form.unidad_tiempo = value;
      },
    },
    montoInicial: {
      get() {
        return this.form.monto_inicial;
      },
      set(value) {
        this.form.monto_inicial = value;
      },
    },
    porcentaje: {
      get() {
        return this.form.porcentaje;
      },
      set(value) {
        this.form.porcentaje = value;
      },
    },
    errorCtlSubasta() {
      const errors = [];
      if (!this.$v.ctlSubasta.$dirty) return errors;
      !this.$v.ctlSubasta.required && errors.push("Campo requerido");
      return errors;
    },
    errorLote() {
      const errors = [];
      if (!this.$v.lote.$dirty) return errors;
      !this.$v.lote.required && errors.push("Lote es requerido");
      return errors;
    },
    errorFechaInicio() {
      const errors = [];
      if (!this.$v.fechaInicio.$dirty) return errors;
      !this.$v.fechaInicio.required && errors.push("Fecha inicio requerida");
      !this.$v.fechaInicio.dateValidation &&
      errors.push("La fecha de inicio debe ser menor a la fecha de fin");
      return errors;
    },
    errorFechaFin() {
      const errors = [];
      if (!this.$v.fechaFin.$dirty) return errors;
      !this.$v.fechaFin.required && errors.push("Fecha inicio requerida");
      !this.$v.fechaFin.dateValidation &&
      errors.push("La fecha de fin debe ser mayor a la fecha de inicio");
      return errors;
    },
    errorSaltoOferta() {
      const errors = [];
      if (!this.$v.saltoOfertas.$dirty) return errors;
      !this.$v.saltoOfertas.required && errors.push("Campo requerido.");
      !this.$v.saltoOfertas.decimalValidation &&
      errors.push("Debe tener un máximo de 2 decimales");
      return errors;
    },

    errorTiempoOferta() {
      const errors = [];
      if (!this.$v.tiempoOferta.$dirty) return errors;
      !this.$v.tiempoOferta.required && errors.push("Campo requerido");
      return errors;
    },
    errorPorcentajeInicial() {
      const errors = [];
      if (!this.$v?.porcentaje.$dirty) return errors;
      !this.$v?.porcentaje.required && errors.push("Campo requerido");
      !this.$v?.porcentaje.decimalValidation &&
      errors.push("Debe tener un máximo de 2 decimales");
      // !this.$v?.maxValue && errors.push("Debe ser menor o igual a 100");
      return errors;
    },
    errorMontoInicial() {
      const errors = [];
      if (!this.$v.montoInicial.$dirty) return errors;
      !this.$v.montoInicial.required && errors.push("Campo requerido");
      if (['resta_monto'].includes(this.ctlSubasta?.tipo_operacion)) {
        !this.$v.montoInicial.validMontoInicial &&
        errors.push("Monto inicial debe ser mayor a Salto entre ofertas");
      }
      !this.$v.montoInicial.decimalValidation &&
      errors.push("Debe tener un máximo de 2 decimales");
      return errors;
    },
    maxValueTiempoOferta() {
      switch (this.unidadTiempo) {
        case "seconds":
          return 59;
        case "minutes":
          return 59;
        case "hours":
          return 23;
        default:
          return 0;
      }
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", ["setInfoProceso", "setParticipando"]),
    async getProceso() {
      const response = await this.services.PacProcesos.getProceso(
          this.$route.params.idProceso
      );

      if (response.status === 200) {
        this.setInfoProceso(response.data);
      }
    },
    tiempoOfertapreventKey(e) {
      if (Number(this.tiempoOferta) >= Number(this.maxValueTiempoOferta)) {
        this.tiempoOferta = 0;
      }
      if (window.event.keyCode >= 48 && window.event.keyCode <= 58) {
        if (this.tiempoOferta.toString().length >= 1 && this.tiempoOferta > 6)
          e.preventDefault();
        if (this.tiempoOferta == 6 && window.event.key > 0) e.preventDefault();
        return;
      }
      e.preventDefault();
    },
    longitudTiempoOff(e) {
      if (this.tiempoOferta.toString().length >= 2) {
        if (window.event.key === "Backspace" || window.event.key === "Delete")
          return;
        e.preventDefault();
      }
      return;
    },
    validatorKey(e) {
      if (window.event.keyCode >= 48 && window.event.keyCode <= 57) return;
      if (window.event.keyCode === 46) return;
      e.preventDefault();
    },
    validarCantidadTiempoOfertas() {
      if (this.tiempoOferta > this.maxValueTiempoOferta)
        this.tiempoOferta = "0";
    },
    async saveSalaConf() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loadingSaveBtn = true;
        const currentDate = moment().format("DD-MM-YYYY 00:00:00");

        if (this.unidadTiempo == "minutes") {
          this.form.tiempo_maximo_oferta = moment(
              `${currentDate} 00:00:00`,
              "DD-MM-YYYY HH:mm:ss"
          )
              .add(this.tiempo_maximo_oferta, "minutes")
              .format("LTS");

          this.form.tiempo_maximo_desierto = moment(
              `${currentDate} 00:00:00`,
              "DD-MM-YYYY HH:mm:ss"
          )
              .add(this.tiempo_maximo_desierto, "minutes")
              .format("LTS");
        }

        let response = null;
        try {
          if (this.$route.params.idSubasta) {
            response = await this.services.PacProcesos.putSalaSubasta(
                this.$route.params.idSubasta,
                this.form
            )
          } else {
            response = await this.services.PacProcesos.addSubasta(
                this.$route.params.idSala,
                this.form
            )
          }
        } catch (e) {
          this.loadingSaveBtn = false;
          throw e;
        }
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Datos guardados con éxito",
        });
        this.$router.back();
        this.loadingSaveBtn = false;
      }
    },
    async getLotes() {
      const {data} =
          await this.services.PacProcesos.obtenerLotesConfigurados(
              this.$route.params.idProceso,
              {
                origen: this.$route.params.idSubasta ? "" : "subasta",
              }
          );

      this.ctlLotes = data;
    },
    async getLoteDetail() {
      this.loadingLote = true;

      const {data} = await this.services.PacProcesos.obtenerDatosLote(
          this.lote
      ).catch(() => (this.loadingLote = false));

      this.loteDetails = data?.SubProcesoObs?.map((col) => ({
        ...col,
        unidad: col?.Presentacion.nombre,
      }));

      await this.getMontoTotalLote()

      this.loadingLote = false;
    },
    setTiemposMaximo(tmax) {
      this.unidadTiempo = "minutes";
      this.tiempo_maximo_oferta = moment.duration(tmax).asMinutes()
    },
    setTiempoDesierto(tDesierto) {
      this.unidadTiempo = "minutes";
      this.tiempo_maximo_desierto = moment.duration(tDesierto).asMinutes()
    },
    async getSubasta() {
      const {data} = await this.services.PacProcesos.getSubastaID(
          this.$route.params.idSubasta
      );
      this.lote = data.id_lote_proceso;
      this.fechaInicio = data.fecha_hora_inicio;
      this.porcentaje = data.porcentaje_inicial;
      this.fechaFin = data.fecha_hora_final;
      this.saltoOfertas = data.salto_oferta;
      this.montoInicial = data.monto_inicial;
      this.ctlSubasta = this.ctlSubastas.find(
          (subasta) => subasta.id === data?.id_ctl_subasta
      );
      this.setTiemposMaximo(data.tiempo_maximo_oferta);
      this.setTiempoDesierto(data.tiempo_maximo_desierto);

      await this.getLoteDetail();
      await this.getMontoTotalLote()

    },
    async getCatalogoSubastas() {
      const {data} = await this.services.Paac.catalogoSubasta()
      this.ctlSubastas = data
    },
    async getMontoTotalLote() {
      const {data} = await this.services.Paac.montoTotalLote(this.lote)
      this.montoTotalLote = data
      if (this.montoInicial === null)
        this.montoInicial = this.montoTotalLote
    }
  },
  destroyed() {
    this.nombreDescriptivo = null;
    this.detalle = null;
    this.fechaInicio = null;
    this.fechaFin = null;
    this.tiempoOferta = 0;
    this.unidadTiempo = null;
    this.saltoOfertas = null;
    this.montoInicial = null;
    this.tiempo_maximo_oferta = null;
    this.tiempo_cierre = null;
    this.lote = null;
  },
  async created() {
    await this.getProceso();
    await this.getLotes();
    await this.getCatalogoSubastas();

    if (this.$route.params.idSubasta) {
      await this.getSubasta();
    }
  },
}
</script>
