<template>
  <section fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <p class="text-h6 secondary--text">{{ sala?.nombre }}</p>
        <v-btn
            @click="
            $router.push({
              name: 'subasta-inversa-configuracion-sala',
              params: {
                idProceso: $route.params.idProceso,
                idSala: $route.params.idSala,
              },
            })
          "
            color="secondary"
            dark
        >
          Agregar subasta
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>Listado de subastas</p>
        <DataTable
            :headers="headers"
            :items="items"
            v-models:select="por_pagina"
            v-models:pagina="pagina"
            :total_registros="total_registros"
            @paginar="paginar"
        >
          <template #[`item.fecha_hora_inicio`]="{ item }">
            {{ moment(item.fecha_hora_inicio).format("DD/MM/YYYY hh:mm a") }}
          </template>
          <template #[`item.fecha_hora_final`]="{ item }">
            {{ moment(item.fecha_hora_final).format("DD/MM/YYYY hh:mm a") }}
          </template>
          <template #[`item.monto_inicial`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_inicial)
            }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="goToEditSubasta(item.id)" color="secondary">
              mdi-pencil
            </v-icon>
            <v-icon
                @click="(id_subasta = item.id), (showModalDelete = true)"
                color="secondary"
            >
              mdi-delete
            </v-icon>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <v-btn color="secondary" outlined @click="$router.back()">
          Volver
        </v-btn>
      </v-col>
    </v-row>
    <ConfirmationDialogComponent
        :show="showModalDelete"
        btnConfirmar="Aceptar"
        title="¿Desea eliminar la subasta seleccionada?"
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        @close="showModalDelete = false"
        @confirm="deleteSubasta"
    />
  </section>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import DataTable from "@/components/DataTableComponent.vue";
import moment from "moment-timezone";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "LotesSalaView",
  components: {
    DataTable,
    ConfirmationDialogComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Lote",
        value: "nombre_lote",
      },
      {
        text: "Fecha y hora de inicio",
        value: "fecha_hora_inicio",
      },
      {
        text: "Fecha y hora de fin",
        value: "fecha_hora_final",
      },
      {
        text: "Monto inicial($)",
        value: "monto_inicial",
      },
      {text: "Acciones", align: "center", value: "actions"},
    ],
    items: [],
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    showModalDelete: false,
    id_subasta: null,
    sala: {},
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),

    breadcrumbsItems() {
      return [
        {
          text: "Proceso",
          disable: true,
          href: "/pac-procesos",
        },
        {
          text: this.procesoData?.codigo_proceso,
          disable: true,
          href: "#",
        },
        {
          text: "Subasta inversa",
          disable: true,
          href: "#",
        },
        {
          text: "Sala",
          disable: true,
          href: "#",
        },
      ];
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", ["setInfoProceso", "setParticipando"]),
    paginar(filtros) {
      const {cantidad_por_pagina, pagina} = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getSubastasSala();
    },
    async getProceso() {
      const response = await this.services.PacProcesos.getProceso(
          this.$route.params.idProceso
      );

      if (response.status === 200) {
        this.setInfoProceso(response.data);
      }
    },
    async getSubastasSala() {
      const {data, headers} =
          await this.services.PacProcesos.getSubastasSala(
              this.$route.params.idSala,
              {
                page: this.pagina,
                per_page: this.por_pagina,
              }
          );
      this.items = data;
      this.total_registros = Number(headers.total_rows);
    },
    async deleteSubasta() {
      await this.services.PacProcesos.deleteSalaSubasta(this.id_subasta)
      this.pushAppMessage({
        show: true,
        type: "success",
        message: "La subasta seleccionada ha sido eliminada",
      });
      this.id_subasta = null;
      this.showModalDelete = false;
      await this.getSubastasSala();
    },
    goToEditSubasta(id_subasta) {
      this.$router.push({
        name: "subasta-inversa-configuracion-sala",
        params: {
          idProceso: this.$route.params.idProceso,
          idSala: this.$route.params.idSala,
          idSubasta: id_subasta,
        },
      });
    },
    async getSala() {
      const {status, data} = await this.services.PacProcesos.getSalaDatos(
          this.$route.params.idSala
      );

      this.sala = data;
    },
  },
  created() {
    this.getProceso();
    this.getSubastasSala();
    this.getSala();
  },
};
</script>
