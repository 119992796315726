var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-breadcrumbs',{attrs:{"large":"","items":_vm.breadcrumbsItems}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('p',{staticClass:"text-h6 secondary--text"},[_vm._v(_vm._s(_vm.sala?.nombre))]),_c('v-btn',{attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.$router.push({
            name: 'subasta-inversa-configuracion-sala',
            params: {
              idProceso: _vm.$route.params.idProceso,
              idSala: _vm.$route.params.idSala,
            },
          })}}},[_vm._v(" Agregar subasta ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Listado de subastas")]),_c('DataTable',{directives:[{name:"models",rawName:"v-models:select",value:(_vm.por_pagina),expression:"por_pagina",arg:"select"},{name:"models",rawName:"v-models:pagina",value:(_vm.pagina),expression:"pagina",arg:"pagina"}],attrs:{"headers":_vm.headers,"items":_vm.items,"total_registros":_vm.total_registros},on:{"paginar":_vm.paginar},scopedSlots:_vm._u([{key:`item.fecha_hora_inicio`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.fecha_hora_inicio).format("DD/MM/YYYY hh:mm a"))+" ")]}},{key:`item.fecha_hora_final`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.fecha_hora_final).format("DD/MM/YYYY hh:mm a"))+" ")]}},{key:`item.monto_inicial`,fn:function({ item }){return [_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.monto_inicial))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.goToEditSubasta(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function($event){(_vm.id_subasta = item.id), (_vm.showModalDelete = true)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Volver ")])],1)],1),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.showModalDelete,"btnConfirmar":"Aceptar","title":"¿Desea eliminar la subasta seleccionada?","message":"De confirmarse la siguiente acción, los cambios no serán reversibles."},on:{"close":function($event){_vm.showModalDelete = false},"confirm":_vm.deleteSubasta}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }